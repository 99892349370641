import { get, has } from 'lodash';

export const isServerError = (error: any): boolean => has(error, 'response.data.errorMessage') || has(error, 'response.data.message');


export const createErrorMessage = (error: any): string => {
    if (isServerError(error)) {
        return get(error, 'response.data.errorMessage', get(error, 'response.data.message'));
    }
    return 'Unfortunately an unknown error occurred!';
};
