import createRootReducer, {IRootState} from './shared/reducers';
import {loadingBarMiddleware} from 'react-redux-loading-bar';
import {applyMiddleware, compose, createStore} from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import DevTools from './config/devtools';
import errorMiddleware from './config/error-middleware';
import loggerMiddleware from './config/logger-middleware';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';


// @ts-ignore
const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');
// @ts-ignore
export const history = createBrowserHistory({basename: baseHref});
const defaultMiddlewares = [routerMiddleware(history), thunkMiddleware, errorMiddleware, promiseMiddleware, loadingBarMiddleware(), loggerMiddleware];
const composedMiddlewares = (middlewares: any) =>
    process.env.NODE_ENV === 'development'
        ? compose(applyMiddleware(...defaultMiddlewares, ...middlewares), DevTools.instrument())
        : compose(applyMiddleware(...defaultMiddlewares, ...middlewares));

const initialize = (initialState?: IRootState, middlewares = []) => createStore(createRootReducer(history), initialState, composedMiddlewares(middlewares));

export default initialize;
