import React, { Suspense, useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    RouteComponentProps, Link
} from 'react-router-dom';
import { IRoute } from "../../shared/interfaces/IRoute";
import indexRoutes from '../../routes/index';
import { Spin } from "antd";
import { IRootState } from "../../shared/reducers";
import { setVisibleModal } from "../../shared/reducers/constant";
import { connect } from "react-redux";
import { getAccessToken } from "../../shared/reducers/app";
import MainContainer from "../MainContainer/MainContainer";

export interface ApplicationContainerProps extends StateProps, DispatchProps, RouteComponentProps { }

const ApplicationContainer = ({ token, ...props }: ApplicationContainerProps) => {
    useEffect(() => {
    }, [token])
    return (
        <>
            <Router>
                <Suspense fallback={<Spin />}>
                    <Switch>
                        {indexRoutes.map((prop: IRoute, key: any) => {
                            if (prop.redirect) {
                                return <Redirect from={prop.path} to={prop.to} key={key} />;
                            } else {
                                return <Route path={prop.path} component={prop.component} key={key} />;
                            }
                        })}
                    </Switch>
                </Suspense>
                {/* } */}
            </Router>
        </>
    )
};

const mapStateToProps = ({ app }: IRootState) => ({
    loading: app.loading,
    token: app.token
});
const mapDispatchToProps = {
    getAccessToken
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationContainer as any);
