import {IWorkspace} from "../interfaces/IWorkspace";

const ACTION_TYPES = {
    SET_VISIBLE : 'constant/SET_VISIBLE',
    CREATE_WORKSPACE: 'constant/CREATE_WORKSPACE',
}
const initialState = {
    visible: false,
    type: ''
}


export type ConstantState = Readonly<typeof initialState>;

// Reducer

// @ts-ignore
export default (state: ConstantState = initialState, action): ConstantState => {
    switch (action.type) {
        case ACTION_TYPES.SET_VISIBLE:
            return {
                ...state,
                visible: action.payload.data.visible,
                type: action.payload.data.type
            };
        default :
            return {...state}
    }
}

// ACTIONS

export const setVisibleModal = (data: object) => (dispatch:any) => {
    return dispatch({ type: ACTION_TYPES.SET_VISIBLE, payload: { data } });
};
