import { FAILURE, REQUEST, SUCCESS } from './action-type.util';
import { push } from "connected-react-router";
import API from '../../config/API';
import { get } from 'lodash'
import { createErrorMessage } from "../utils/request-utils";
import { IUser } from "../interfaces/IUser";
import { IWorkspace } from "../interfaces/IWorkspace";
import { ICategory } from "../interfaces/ICategory";

const ACTION_TYPES = {
    GET_ACCESS_TOKEN: 'app/GET_ACCESS_TOKEN',
    GET_CATEGORIES: 'app/GET_CATEGORIES'
}


// @ts-ignore
let token = JSON.parse(localStorage.getItem('token'));
const initialState = {
    loading: false,
    hasError: false,
    errorMessage: '',
    loggedIn: false,
    token: token ? { loggedIn: true, token } : {},
    categories: [] as ReadonlyArray<ICategory>

}

export type AppState = Readonly<typeof initialState>;

// Reducer
export default (state: AppState = initialState, action: { type: any; payload: any; }): AppState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.GET_ACCESS_TOKEN):
            return {
                ...state,
                loading: true,
                loggedIn: false,
                token: {}
            };
        case REQUEST(ACTION_TYPES.GET_CATEGORIES):
            return {
                ...state,
                loading: true,
                categories: []
            }
        case SUCCESS(ACTION_TYPES.GET_ACCESS_TOKEN):
            return {
                ...state,
                loading: false,
                loggedIn: true,
                token: get(action.payload, 'data')
            };
        case SUCCESS(ACTION_TYPES.GET_CATEGORIES):
            return {
                ...state,
                loading: true,
                categories: get(action.payload, 'data')
            }
        case FAILURE(ACTION_TYPES.GET_ACCESS_TOKEN):
        case FAILURE(ACTION_TYPES.GET_CATEGORIES):
            return {
                ...state,
                loading: false,
                hasError: true,
                errorMessage: createErrorMessage(action.payload),
            };
        default:
            return { ...state };
    }
}

// Actions

export const getAccessToken = (data: IUser) => async (dispatch: any) => {
    const result = await dispatch({
        type: ACTION_TYPES.GET_ACCESS_TOKEN,
        payload: API.accessToken(data),
    });
    // localStorage.setItem('token', JSON.stringify(get(result.value, 'data')));
    API.setToken(JSON.stringify(get(result.value, 'data')));
    return result
};

export const fetchCategories = () => async (dispatch: any) => {
    return await dispatch({
        type: ACTION_TYPES.GET_CATEGORIES,
        payload: API.getCategories(),
    });
};

