import { lazy } from 'react';
import { IRoute } from "../shared/interfaces/IRoute";
const MainContainer = lazy(() => import('../containers/MainContainer/MainContainer'));
const SignIn = lazy(() => import('../views/SignIn'));
const SignUp = lazy(() => import('../views/SignUp'));

// const Login = lazy(() => import('../views/Login/Login'));
// import ForgetPassword from "../views/Login/ForgetPassword";

const indexRoutes: IRoute[] = [
    // { path: "/Users", component: Main },
    { path: "/Panel/Workspace", component: MainContainer },
    // { path: "/Panel/Login", component: Login },
    // { path: "/Panel/ForgetPassword", component: ForgetPassword },
    { path: "/", component: SignIn },
    { path: "/Panel/SignUp", component: SignUp },
    { path: "/Panel/SignIn", component: SignIn },
    { redirect: true, path: "/", to: "/Panel/Workspace" }

];

export default indexRoutes;
