import React from 'react';
import ApplicationContainer from "./containers/ApplicationContainer/ApplicationContainer";
import {hot} from 'react-hot-loader';
import {connect} from 'react-redux';
export const App = () => {
    return (
        <ApplicationContainer />
    );
};

export default connect(null, null)(hot(module)(App as any));
