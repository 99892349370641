import React from 'react';
import DockMonitor from 'redux-devtools-dock-monitor';
import { createDevTools } from 'redux-devtools';
import  LogMonitor  from 'redux-devtools-log-monitor';

export default createDevTools(
  <DockMonitor toggleVisibilityKey={'ctrl-h'} changePositionKey={'ctrl-q'} defaultIsVisible={false}>
    <LogMonitor/>
  </DockMonitor>
);
