import {FAILURE, REQUEST, SUCCESS} from './action-type.util';
import {IModule} from "../interfaces/IModule";
import {createErrorMessage} from "../utils/request-utils";
import get from "lodash/get";
import {IWorkflow} from "../interfaces/IWorkflow";
import API from "../../config/API";

const ACTION_TYPES = {
    FETCH_MODULES: 'module/FETCH_MODULES',
    FETCH_MODULE_BY_ID: 'module/FETCH_MODULE_BY_ID'
}

const initialState = {
    loading: false,
    modules: [] as ReadonlyArray<IModule>,
    hasError: false,
    errorMessage: '',
    module: {} as IModule
}

export type ModuleState = Readonly<typeof initialState>;


//Reducer
export default (state: ModuleState = initialState, action: { type: any; payload: any; }): ModuleState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_MODULES):
            return {
                ...state,
                loading: true,
                modules: []
            }
        case REQUEST(ACTION_TYPES.FETCH_MODULE_BY_ID):
            return {
                ...state,
                loading: true,
                module: {}
            }
        case SUCCESS(ACTION_TYPES.FETCH_MODULE_BY_ID):
            return {
                ...state,
                loading: true,
                module: get(action.payload, 'data')
            }
        case SUCCESS(ACTION_TYPES.FETCH_MODULES):
            return {
                ...state,
                loading: false,
                modules: get(action.payload, 'data')
            }
        case FAILURE(ACTION_TYPES.FETCH_MODULE_BY_ID):
        case FAILURE(ACTION_TYPES.FETCH_MODULES):
            return {
                ...state,
                loading: false,
                hasError: true,
                errorMessage: createErrorMessage(action.payload),
            }
        default:
            return {
                ...state
            }
    }
}

// Actions
export const fetchModules = () => async (dispatch: any) => {
    return await dispatch({
        type: ACTION_TYPES.FETCH_MODULES,
        payload: API.getModules(),
    });
}
export const fetchModuleById = (id: any) => async (dispatch: any) => {
    return await dispatch({
        type: ACTION_TYPES.FETCH_MODULE_BY_ID,
        payload: API.getModuleById(id),
    });
}
