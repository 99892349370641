import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import './index.less'
import {Provider} from 'react-redux';
import initStore from './store';
import setupAxiosInterceptors from "./config/axios-interceptor";
setupAxiosInterceptors()
const store = initStore();
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
        ,
    document.getElementById('output')
);