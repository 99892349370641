import {FAILURE, REQUEST, SUCCESS} from './action-type.util';
import API from '../../config/API';
import {IWorkflow} from "../interfaces/IWorkflow";
import {createErrorMessage} from "../utils/request-utils";
import get from 'lodash/get'

const ACTION_TYPES = {
    FETCH_WORKFLOWS: 'workflow/FETCH_WORKFLOWS',
    CREATE_WORKFLOW: 'workflow/CREATE_WORKFLOW',
    GET_WORKFLOW_BY_ID: 'workflow/GET_WORKFLOW_BY_ID',
    UPDATE_WORKFLOW: 'workflow/UPDATE_WORKFLOW',
    DELETE_WORKFLOW:'workflow/DELETE_WORKFLOW'
}

const initialState = {
    workflows: [] as ReadonlyArray<IWorkflow>,
    loading: false,
    hasError: false,
    errorMessage: ''
}

export type WorkflowState = Readonly<typeof initialState>;


// Reducer
export default (state: WorkflowState = initialState, action: { type: any; payload: any; }): WorkflowState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.DELETE_WORKFLOW):
        case REQUEST(ACTION_TYPES.UPDATE_WORKFLOW):
        case REQUEST(ACTION_TYPES.GET_WORKFLOW_BY_ID):
        case REQUEST(ACTION_TYPES.CREATE_WORKFLOW):
        case REQUEST(ACTION_TYPES.FETCH_WORKFLOWS):
            return {
                ...state,
                loading: true,
                workflows: []
            };
        case SUCCESS(ACTION_TYPES.DELETE_WORKFLOW):
        case SUCCESS(ACTION_TYPES.UPDATE_WORKFLOW):
        case SUCCESS(ACTION_TYPES.GET_WORKFLOW_BY_ID):
        case SUCCESS(ACTION_TYPES.CREATE_WORKFLOW):
        case SUCCESS(ACTION_TYPES.FETCH_WORKFLOWS):
            return {
                ...state,
                loading: false,
                workflows: get(action.payload, 'date')
            };
        case FAILURE(ACTION_TYPES.DELETE_WORKFLOW):
        case FAILURE(ACTION_TYPES.UPDATE_WORKFLOW):
        case FAILURE(ACTION_TYPES.GET_WORKFLOW_BY_ID):
        case FAILURE(ACTION_TYPES.CREATE_WORKFLOW):
        case FAILURE(ACTION_TYPES.FETCH_WORKFLOWS):
            return {
                ...state,
                loading: false,
                hasError: true,
                errorMessage: createErrorMessage(action.payload),
            };
        default:
            return {...state};
    }

}
// Actions
export const fetchWorkflows = (data: IWorkflow) => async (dispatch: any) => {
    const result = await dispatch({
        type: ACTION_TYPES.FETCH_WORKFLOWS,
        payload: API.getWorkflows(data),
    });
    return result
}
export const addWorkflow = (data: IWorkflow) => async (dispatch: any) => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_WORKFLOW,
        payload: API.createWorkflow(data),
    });
    return result
}
export const fetchWorkflowById = (data: IWorkflow) => async (dispatch: any) => {
    const result = await dispatch({
        type: ACTION_TYPES.FETCH_WORKFLOWS,
        payload: API.getWorkflowById(data),
    });
    return result
}
export const editWorkflow = (data: IWorkflow) => async (dispatch: any) => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_WORKFLOW,
        payload: API.updateWorkflow(data),
    });
    return result
}
export const removeWorkflow = (data: IWorkflow) => async (dispatch: any) => {
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_WORKFLOW,
        payload: API.deleteWorkflow(data),
    });
    return result
}
