import {SUCCESS, FAILURE, REQUEST} from "./action-type.util";
import API from '../../config/API';
import {IUser} from "../interfaces/IUser";
import {createErrorMessage} from "../utils/request-utils";
import get from 'lodash/get'

const ACTION_TYPES = {
    FETCH_USERS: 'user/FETCH_USERS',
    FETCH_USER_BY_ID: 'user/FETCH_USER_BY_ID',
    CREATE_USER: 'user/CREATE_USER',
    UPDATE_USER: 'user/UPDATE_USER',
    CREATE_OPEN_USER:'user/CREATE_OPEN_USER',
    UPDATE_ME:'user/UPDATE_ME',
    FETCH_USER_BY_TOKEN:'user/FETCH_USER_BY_TOKEN',
}

const initialState = {
    loading: false,
    users: [] as ReadonlyArray<IUser>,
    hasError: false,
    errorMessage: ''
}

export type UserState = Readonly<typeof initialState>;


//Reducer
export default (state: UserState = initialState, action: { type: any; payload: any; }): UserState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_USER_BY_TOKEN):
        case REQUEST(ACTION_TYPES.FETCH_USERS):
            return {
                ...state,
                loading: true,
                users: []
            }
        case SUCCESS(ACTION_TYPES.FETCH_USER_BY_TOKEN):
        case SUCCESS(ACTION_TYPES.FETCH_USERS):
            return {
                ...state,
                loading: false,
                users: get(action.payload, 'data')
            }
        case FAILURE(ACTION_TYPES.FETCH_USER_BY_TOKEN):
        case FAILURE(ACTION_TYPES.FETCH_USERS):
            return {
                ...state,
                loading: false,
                hasError: true,
                errorMessage: createErrorMessage(action.payload),
            };
        default:
            return {
                ...state
            }
    }
}
// Actions

export const fetchUsers = () => async (dispatch: any) => {
    return await dispatch({
        type: ACTION_TYPES.FETCH_USERS,
        payload: API.getUsers(),
    });
};
export const fetchUserById = (data:IUser) => async (dispatch: any) => {
    return await dispatch({
        type: ACTION_TYPES.FETCH_USER_BY_ID,
        payload: API.getUserById(data),
    });
};

export const fetchUser = () => async (dispatch: any) => {
    return await dispatch({
        type: ACTION_TYPES.FETCH_USER_BY_ID,
        payload: API.getMe(),
    });
};
export const addOpenUser = (data: IUser) => async (dispatch: any) => {
    return await dispatch({
        type: ACTION_TYPES.CREATE_OPEN_USER,
        payload: API.createOpenUser(data),
    });
};
export const addUser = (data: IUser) => async (dispatch: any) => {
    return await dispatch({
        type: ACTION_TYPES.CREATE_OPEN_USER,
        payload: API.createUser(data),
    });
};
export const editUser = (data: IUser) => async (dispatch: any) => {
    return await dispatch({
        type: ACTION_TYPES.CREATE_OPEN_USER,
        payload: API.updateUser(data),
    });
};
export const editMe = (data: IUser) => async (dispatch: any) => {
    return await dispatch({
        type: ACTION_TYPES.CREATE_OPEN_USER,
        payload: API.updateMe(data),
    });
};
