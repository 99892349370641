import axios from 'axios';
import { get, has, set } from 'lodash';

const TIMEOUT = 60 * 1000;
axios.defaults.timeout = TIMEOUT;

const setupAxiosInterceptors = () => {
  const onRequestSuccess = (config: any) => {
    return config;
  };
  let logger : any;
  if (get(window, 'DD_LOGS.logger')) {
    logger = get(window, 'DD_LOGS').createLogger('topLogger');
    logger.addContext('product', 'lagrange');
  }
  const onResponseSuccess = (response:any) => {
    if (has(response, 'data')) {
      set(response, 'data', get(response, 'data'));
    }
    if (logger) {
      logger.info('TOP: Success response log', response);
    }
    return response;
  };
  const onResponseError = (err:any) => {
    if (logger) {
      logger.error('TOP: Error response log', err);
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
