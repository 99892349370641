import {combineReducers} from 'redux';
import app, {AppState} from './app';
import constant, {ConstantState} from './constant';
import {connectRouter, RouterState} from 'connected-react-router';
import workspace, {WorkspaceState} from "./workspace";
import workflow, {WorkflowState} from "./workflow";
import user, {UserState} from "./user";
import module, {ModuleState} from "./module";

export interface IRootState {
    readonly app: AppState;
    readonly constant: ConstantState;
    readonly router: RouterState;
    readonly workspace: WorkspaceState
    readonly workflow: WorkflowState
    readonly user: UserState
    readonly module : ModuleState

}

const createRootReducer = (history: any) => combineReducers<IRootState>({
    app,
    constant,
    workspace,
    workflow,
    user,
    module,
    router: connectRouter(history),
});

export default createRootReducer;
