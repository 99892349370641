import {FAILURE, REQUEST, SUCCESS} from './action-type.util';
import API from '../../config/API';
import get from 'lodash/get'
import {createErrorMessage} from "../utils/request-utils";
import {IWorkspace} from "../interfaces/IWorkspace";

const ACTION_TYPES = {
    FETCH_WORKSPACES: 'workspace/FETCH_WORKSPACES',
    CREATE_WORKSPACE: 'workspace/CREATE_WORKSPACE',
    REMOVE_WORKSPACE: 'workspace/REMOVE_WORKSPACE',
    FETCH_WORKSPACE_BY_ID: 'workspace/FETCH_WORKSPACE_BY_ID'
}


// @ts-ignore
let token = JSON.parse(localStorage.getItem('token'));
const initialState = {
    loading: false,
    hasError: false,
    errorMessage: '',
    loggedIn: false,
    token: token ? {loggedIn: true, token} : {},
    workspaces: [] as ReadonlyArray<IWorkspace>,
    workspace: null
}

export type WorkspaceState = Readonly<typeof initialState>;

// Reducer
export default (state: WorkspaceState = initialState, action: { type: any; payload: any; }): WorkspaceState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_WORKSPACE_BY_ID):
            return {
                ...state,
                loading: true,
                workspace: null
            };
        case REQUEST(ACTION_TYPES.CREATE_WORKSPACE):
        case REQUEST(ACTION_TYPES.FETCH_WORKSPACES):
        case REQUEST(ACTION_TYPES.REMOVE_WORKSPACE):
            return {
                ...state,
                loading: true,
                workspaces: []
            };
        case SUCCESS(ACTION_TYPES.FETCH_WORKSPACE_BY_ID):
            return {
                ...state,
                loading: false,
                workspace: get(action.payload, 'data')
            };
        case SUCCESS(ACTION_TYPES.CREATE_WORKSPACE):
        case SUCCESS(ACTION_TYPES.FETCH_WORKSPACES):
        case SUCCESS(ACTION_TYPES.REMOVE_WORKSPACE):
            return {
                ...state,
                loading: false,
                workspaces: get(action.payload, 'data')
            };
        case FAILURE(ACTION_TYPES.FETCH_WORKSPACE_BY_ID):
        case FAILURE(ACTION_TYPES.CREATE_WORKSPACE):
        case FAILURE(ACTION_TYPES.FETCH_WORKSPACES):
        case FAILURE(ACTION_TYPES.REMOVE_WORKSPACE):
            return {
                ...state,
                loading: false,
                hasError: true,
                errorMessage: createErrorMessage(action.payload),
            };
        default:
            return {...state};
    }
}

// Actions
export const fetchWorkspaces = () => async (dispatch: any) => {
    const result = await dispatch({
        type: ACTION_TYPES.FETCH_WORKSPACES,
        payload: API.getWorkspaces(),
    });
    return result
};

export const fetchWorkspaceById = (data: IWorkspace) => async (dispatch: any) => {
    const result = await dispatch({
        type: ACTION_TYPES.FETCH_WORKSPACE_BY_ID,
        payload: API.getWorkspace(data),
    });
    return result
}
export const addWorkspace = (data: IWorkspace) => async (dispatch: any) => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_WORKSPACE,
        payload: API.createWorkspace(data),
    });
    return result
}
export const removeWorkspace = (data: IWorkspace) => async (dispatch: any) => {
    const result = await dispatch({
        type: ACTION_TYPES.REMOVE_WORKSPACE,
        payload: API.deleteWorkspace(data),
    });
    return result
}
