import axios from 'axios';
import { get } from 'lodash';
import { IUser } from "../shared/interfaces/IUser";
import { IWorkspace } from "../shared/interfaces/IWorkspace";
import { IWorkflow } from "../shared/interfaces/IWorkflow";
import { IModule } from "../shared/interfaces/IModule";
import { IModulePort } from "../shared/interfaces/IModulePort";

const API_URL = 'https://staging-api.studio.lagrange.ai/api/v1';
const LOGIN_URL = `${API_URL}/login`

//login
// @ts-ignore
let token = JSON.parse(localStorage.getItem('token'));
let access_token = get(token, 'access_token');
let token_type = get(token, 'token_type');
const setToken = (token) => {
    localStorage.setItem('token', token);
    token = JSON.parse(token);
    access_token = get(token, 'access_token');
    token_type = get(token, 'token_type');
}
const accessToken = (data: IUser) => {
    const file = new FormData();
    Object.keys(data).forEach((key) => {
        // @ts-ignore
        file.append(key, data[key])
    })
    return axios.request({
        method: 'post',
        url: `${LOGIN_URL}/access-token`,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: file
    });
};
const resetPassword = (data: any) => {
    const file = new FormData();
    Object.keys(data).forEach((key) => {
        // @ts-ignore
        file.append(key, data[key])
    })

    return axios.request({
        method: 'post',
        url: `${LOGIN_URL}/reset-password`,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: file
    });
};

// workspace
const getWorkspaces = () => {
    token = JSON.parse(localStorage.getItem('token') || '{}');
    const access_token = get(token, 'access_token')
    const token_type = get(token, 'token_type');
    return axios.request({
        method: 'get',
        url: `${API_URL}/workspaces`,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token_type} ${access_token}`
        },
    });
}
const createWorkspace = (data: IWorkspace) => {
    return axios.request({
        method: 'post',
        url: `${API_URL}/workspaces`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
        data: data
    });
}
const getWorkspace = (data: IWorkspace) => {
    return axios.request({
        method: 'get',
        url: `${API_URL}/workspaces/${data.id}`,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token_type} ${access_token}`
        },
    });
}

const updateWorkspace = (data: IWorkspace) => {
    return axios.request({
        method: 'put',
        url: `${API_URL}/workspaces/${data.id}`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
        data: data
    });
}
const deleteWorkspace = (data: IWorkspace) => {
    return axios.request({
        method: 'delete',
        url: `${API_URL}/workspaces/${data.id}`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
    });
}
// Category

const getCategories = () => {
    return axios.request({
        method: 'get',
        url: `${API_URL}/categories`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
    });
}

// Workflow
const getWorkflows = (data: IWorkflow) => {
    return axios.request({
        method: 'get',
        url: `${API_URL}/workflows/?workspace_id:${data.workspace_id}`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
    });
}
const getWorkflowById = (data: IWorkflow) => {
    return axios.request({
        method: 'get',
        url: `${API_URL}/workflows/${data.id}`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
    });
}
const createWorkflow = (data: IWorkflow) => {
    return axios.request({
        method: 'post',
        url: `${API_URL}/workflows`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
        data: data
    });
}
const updateWorkflow = (data: IWorkflow) => {
    return axios.request({
        method: 'put',
        url: `${API_URL}/workflows/${data.id}`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
        data: data
    });
}
const deleteWorkflow = (data: IWorkflow) => {
    return axios.request({
        method: 'delete',
        url: `${API_URL}/workflows/${data.id}`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
    });
}
// user
const getUsers = () => {
    return axios.request({
        method: 'get',
        url: `${API_URL}/users`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
    });
}
const createUser = (data: IUser) => {
    return axios.request({
        method: 'post',
        url: `${API_URL}/users`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
        data: data
    });
}
const getMe = () => {
    return axios.request({
        method: 'get',
        url: `${API_URL}/users/me`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
    });
}
const updateMe = (data: IUser) => {
    return axios.request({
        method: 'put',
        url: `${API_URL}/users/me`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
        data: data
    });
}
const createOpenUser = (data: IUser) => {
    return axios.request({
        method: 'post',
        url: `${API_URL}/users/open`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
        data: data
    });
}
const getUserById = (data: IUser) => {
    return axios.request({
        method: 'get',
        url: `${API_URL}/users/?User_id:${data.id}`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
    });
}
const updateUser = (data: IUser) => {
    return axios.request({
        method: 'put',
        url: `${API_URL}/users/?User_id:${data.id}`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
        data: data
    });
}

// modules
const getModules = () => {
    return axios.request({
        method: 'get',
        url: `${API_URL}/modules`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
    });
}
const getModuleById = (id: any) => {
    return axios.request({
        method: 'get',
        url: `${API_URL}/modules/${id}`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
    });
}
// module port
const getModulePorts = () => {
    return axios.request({
        method: 'get',
        url: `${API_URL}/module-ports`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
    });
}
const getModulePortById = (data: IModulePort) => {
    return axios.request({
        method: 'get',
        url: `${API_URL}/module-ports/${data.id}`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
    });
}



// text input

const getTextInputs = () => {
    return axios.request({
        method: 'get',
        url: `${API_URL}/text_inputs`,
        headers: {
            Authorization: `${token_type} ${access_token}`
        },
    });
}
export default {
    setToken,
    accessToken,
    resetPassword,
    getWorkspaces,
    createWorkspace,
    getWorkspace,
    updateWorkspace,
    deleteWorkspace,
    getCategories,
    getWorkflows,
    createWorkflow,
    getWorkflowById,
    updateWorkflow,
    deleteWorkflow,
    getUsers,
    createUser,
    getMe,
    updateMe,
    createOpenUser,
    getUserById,
    updateUser,
    getModules,
    getModulePorts,
    getModulePortById,
    getModuleById,
    getTextInputs,
};
